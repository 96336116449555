import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./Pages/PrivateRoute";
import Dashboard from "./Pages/Dashboard";
import Customers from "./Pages/Clients";
import AddOrder from "./Pages/Clients/AddClient";
import Order from "./Pages/Clients/OrderId";
import AddClient from "./Pages/Clients/AddClient";
import Client from "./Pages/Clients/ClientId";
import PageNotFound from "./Pages/PageNotFound";
import AttentionDetail from "./Pages/Attentions/AttentionDetail";
import Attentions from "./Pages/Attentions";
import SessionDetail from "./Pages/Sessions/SessionDetail";
import AuthProvider from "./AuthProvider";
import Config from "./Pages/Config";
import Stats from "./Pages/Stats";
import Quiz from "Pages/Survey";
import Login from "Pages/Login";
import Survey from "Pages/Survey";
import Home from "Pages/Home";
import { SidebarInset, SidebarProvider } from "Components/Navbar/ui/sidebar";
import { AppSidebar } from "Components/Navbar/app-sidebar";
import Admin from "Pages/Admin";
import Navbar from "Navbar";

function App() {
  return (
    <Router>
      <AuthProvider>
        <RouteWithNavbar>
          <Routes>
            <Route path="/" element={<PrivateRoute component={Home} />} />
            <Route path="/home" element={<PrivateRoute component={Home} />} />
            <Route
              path="/clients"
              element={<PrivateRoute component={Customers} />}
            />
            <Route
              path="/attentions"
              element={<PrivateRoute component={Attentions} />}
            />
            <Route
              path="/attention/:id"
              element={<PrivateRoute component={AttentionDetail} />}
            />
            <Route
              path="/customer/add"
              element={<PrivateRoute component={AddClient} />}
            />
            <Route
              path="/customer/:id"
              element={<PrivateRoute component={Client} />}
            />
            <Route
              path="/session/:id"
              element={<PrivateRoute component={SessionDetail} />}
            />
            <Route path="/nps" element={<PrivateRoute component={Stats} />} />
            <Route path="/stats" element={<PrivateRoute component={Stats} />} />
            <Route
              path="/survey"
              element={<PrivateRoute component={Survey} />}
            />
            <Route
              path="/config"
              element={<PrivateRoute component={Config} />}
            />
            <Route path="/admin" element={<PrivateRoute component={Admin} />} />
            <Route
              path="/*"
              element={<PrivateRoute component={PageNotFound} />}
            />
          </Routes>
        </RouteWithNavbar>
      </AuthProvider>
    </Router>
  );
}

function RouteWithNavbar({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default App;
