import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserNotAllowed from "./UserNotAllowed";

export function PrivateRoute({ component, requiredRoles = ["user"] }) {
  const Component = withAuthenticationRequired(component, {
    returnTo: window.location.pathname,
  });
  /* if (getClaims && !requiredRoles.every((rr) => getClaims.includes(rr))) {
    return <UserNotAllowed />;
  } */
  return <Component />;
}
